import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  constructor(private http: HttpClient) {}

  PatientDetails(): Observable<any> {
    let apiURL = API_URL + "get-patient-details";
    return this.http.get(apiURL);
  }
  
}
