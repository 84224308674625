<div class="gl-alert" *ngIf="is_next_apt">
  <div>
    You have an appointment scheduled at
    {{ is_next_apt.appointment_time }} 
    ({{ is_next_apt.timezone }}) on
    {{ is_next_apt.booking_date }} with Dr.
    {{ is_next_apt.doctor_name | titlecase }}.<a
      class="deferred-link cursor-pointer"
      (click)="reloadPage()">Click Here </a
    >to view the appointment.
  </div>
</div>
<!-- <app-header></app-header> -->
<nav
  class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
  id="sidebar"
>
  <div class="container-fluid">
    <!-- Toggler -->
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#sidebarCollapse"
      aria-controls="sidebarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
      [ngClass]="{ show: show }"
      (click)="toggleCollapse()"
    >
      <svg
        _ngcontent-qpv-c105=""
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
      >
        <path
          _ngcontent-qpv-c105=""
          stroke="#fff"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="2"
          d="M4 7h22M4 15h22M4 23h22"
        ></path>
      </svg>
      <svg
        _ngcontent-oyt-c105=""
        version="1.1"
        id="Capa_1"
        fill="#fff"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512.001 512.001"
        xml:space="preserve"
        class="close"
        style="enable-background: new 0 0 512.001 512.001"
      >
        <path
          _ngcontent-oyt-c105=""
          d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717    L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859    c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287    l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285    L284.286,256.002z"
        ></path>
      </svg>
    </button>

    <!-- Brand -->
    <div class="profilepic">
      <div
        class="avatar avatar-sm avatar-online dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-template #notShowImage>
          <img
            class="avatar-img rounded-circle"
            src="assets/images/default_profilepic.png"
            alt="..."
          />
        </ng-template>

        <img
          class="avatar-img rounded-circle"
          src="{{ profile_pic }}"
          *ngIf="profile_pic !== null; else notShowImage"
          id="make_pic"
          onerror="this.src='/assets/images/default_profilepic.png'"
        />
      </div>
      <div class="patinfo">
        <h3>{{ patient_name | titlecase }}</h3>
        <div class="pid">{{ uniqueId }}</div>
        <a routerLink="/patient-profile" class="update">View Profile</a>
        <div *ngIf="membership_details?.status == 'active'">
          Membership is <span>{{membership_details?.status}}</span>
          <p>Since : {{membership_details.transaction.created_at | date:'mediumDate'}}</p>
        </div>
      </div>
    </div>

    <!-- User (xs) -->
    <div class="navbar-user d-md-none">
      <!-- Dropdown -->
      <div class="dropdown">
        <!-- Toggle -->
        <a
          href="#"
          id="sidebarIcon"
          class="dropdown-toggle"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="avatar avatar-sm avatar-online">
            <!-- <img
              src="/assets/images/avatar-1.jpg"
              class="avatar-img rounded-circle"
              alt="..."
            /> -->
          </div>
        </a>

        <!-- Menu -->
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="sidebarIcon"
        >
          <a routerLink="/patient-profile" class="dropdown-item">Profile</a>
          <hr class="dropdown-divider" />
          <a (click)="logout()" class="dropdown-item cursor-pointer">Logout</a>
        </div>
      </div>
    </div>

    <!-- Collapse -->
    <div
      class="collapse navbar-collapse"
      id="sidebarCollapse"
      [ngClass]="{ show: show }"
    >
      <!-- <form class="mt-4 mb-3 d-md-none">
        <div class="input-group input-group-rounded input-group-merge">
          <input
            type="search"
            class="form-control form-control-rounded form-control-prepended"
            placeholder="Search"
            aria-label="Search"
          />
          <div class="input-group-prepend">
            <div class="input-group-text">
              <span class="fe fe-search"></span>
            </div>
          </div>
        </div>
      </form> -->

      <app-sidebar></app-sidebar>
    </div>
    <!-- / .navbar-collapse -->
  </div>
</nav>

<div class="main-content">
  <nav class="navbar navbar-expand-md navbar-light d-md-flex" id="topbar">
    <div class="container-fluid">
      <div class="form-inline mr-4 d-none d-md-flex">
        <a _ngcontent-rvi-c105="" class="navbar-brand cursor-pointer">
          <img
            src="../../../assets/images/sc_logo.png"
            alt="..."
            class=""
            style="height: 50px"
        /></a>
      </div>
      <!-- <form class="form-inline mr-4 d-none d-md-flex">
        <div
          class="input-group input-group-flush input-group-merge"
          data-list='{"valueNames": ["name"]}'
        >
         <input
            type="search"
            class="form-control form-control-prepended dropdown-toggle list-search"
            data-toggle="dropdown"
            placeholder="Search"
            aria-label="Search"
          /> 
          <div class="input-group-prepend">
            <div class="input-group-text">
             
            </div>
          </div>
        </div>
      </form>
    -->

      <div class="navbar-user">
        <a
          class="btn btn-primary btn-sm cursor-pointer allcaps"
          (click)="gotoHome()"
          ><i class="fe fe-arrow-left"></i> Back to Doctor Listing
        </a>

        <!-- Dropdown profile pic-->
        <!-- <div class="dropdown">
         
          <a
            class="avatar avatar-sm avatar-online dropdown-toggle cursor-pointer"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <ng-template #notShowImage>
              <img
                class="avatar-img rounded-circle"
                src="assets/images/default_profilepic.png"
                alt="..."
              />
            </ng-template>

            <img
              class="avatar-img rounded-circle"
              src="{{ assetUrl + profile_pic }}"
              *ngIf="profile_pic !== null; else notShowImage"
              id="make_pic"
              onerror="this.src='/assets/images/default_profilepic.png'"
            />
          </a>

         
          <div class="dropdown-menu dropdown-menu-right">
            <a routerLink="/patient-profile" class="dropdown-item">Profile</a>
            <hr class="dropdown-divider" />
            <a (click)="logout()" class="dropdown-item cursor-pointer"
              >Logout</a
            >
          </div>
        </div> -->
      </div>
    </div>
  </nav>
  <router-outlet></router-outlet>
</div>
