import { HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { LoginService } from "../login/login.service";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class SCInterceptor implements HttpInterceptor {
  constructor(private loginservice: LoginService, private router: Router) {}

  intercept(
    req: import("@angular/common/http").HttpRequest<any>,
    next: import("@angular/common/http").HttpHandler
  ): import("rxjs").Observable<import("@angular/common/http").HttpEvent<any>> {
    const token = this.loginservice.getToken();

    let headers = req.headers.set("Authorization", "Bearer " + token);
    return next.handle(req.clone({ headers })).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
           if(error.error.reason =="otp_not_verified"){
              this.router.navigate(['registration/otp-verification']);
           }else{
              this.loginservice.logout();
           }
        }
        return throwError(error);
      })
    );
  }
}
