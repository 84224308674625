import { HeaderComponent } from "src/app/layout/header/header.component";
import { LoginService } from "../../login/login.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  show: boolean = false;

  constructor(
    private loginservice: LoginService,
    public headercomponent: HeaderComponent
  ) {}

  ngOnInit(): void {}
  logout() {
    this.loginservice.logout();
  }
}
