import { Component, OnInit } from "@angular/core";
import { environment } from "./../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit {
  loading = true;
  timeOut = "";
  constructor(private cookieService: CookieService, private router: Router) {}

  ngOnInit() {
    this.loading = true;
    const url = environment.baseUrl.replace(/(^\w+:|^)\/\//, "");
    this.cookieService.delete("access_token", "/", "." + url);
    setTimeout(() => {
      window.location.href = environment.mainUrl;
    }, 100);
  }
}
