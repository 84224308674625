<ngx-loading
  [show]="loading"
  [config]="{
    backdropBackgroundColour: '#fff',
    primaryColour: '#000',
    secondaryColour: '#000',
    tertiaryColour: '#000'
  }"
>
</ngx-loading>
