import { Routes } from "@angular/router";
import { AuthGuard } from "./_guards/auth.guard";
import { LogoutComponent } from "./logout/logout.component";
import { HeaderComponent } from "./layout/header/header.component";
import { LoaderComponent } from "./../app/shared/loader/loader.component";

export const AppRoutes: Routes = [
  {
    path: "",
    component: LoaderComponent,
  },
  {
    path: "",
    canActivate: [AuthGuard],
    component: HeaderComponent,
    children: [
      // {
      //   path: "patient-dashboard",
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import("./patient-dashboard/patient-dashboard.module").then(
      //       (module) => module.PatientDashboarddModule
      //     ),
      // },
      {
        path: "patient-profile",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./patient-profile/patient-profile.module").then(
            (module) => module.PatientProfiledModule
          ),
      },
      {
        path: "patient-reports",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./reports/reports.module").then(
            (module) => module.ReportsdModule
          ),
      },
      {
        path: "my-doctors",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./my-doctors/my-doctors.module").then(
            (module) => module.MyDoctorModule
          ),
      },
      {
        path: "appointments",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./patient-appointments/patient-appointments.module").then(
            (module) => module.PatientAppointmentsModule
          ),
      },
      {
        path: "appointment-details",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./appointment-details/appointment-details.module").then(
            (module) => module.AppointmentDetailsModule
          ),
      },
         {
        path: "invoice-product",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./invoice-product/invoice-product.module").then(
            (module) => module.ProductInvoiceModule
          ),
      },
      // {
      //   path: "old-appointment-details",
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import("./old-appointment-details/appointment-details.module").then(
      //       (module) => module.AppointmentDetailsModule
      //     ),
      // },
      {
        path: "agora-call",
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import("./agora-call/agora-call.module").then(
            (module) => module.AgoraCallModule
          ),
      },
      {
        path: "second-opinion",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./second-opinion/second-opinion.module").then(
            (module) => module.SecondOpinionModule
          ),
      },
      {
        path: "second-opinion-details",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./second-opinion-details/second-opinion-details.module").then(
            (module) => module.SecondOpinionDetailsModule
          ),
      },
      {
        path: "transactions",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./transactions/transactions.module").then(
            (module) => module.TransactionsModule
          ),
      },
      {
        path: "help-videos",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./help-videos/help-videos.module").then(
            (module) => module.HelpVideosModule
          ),
      },
    ],
  },
  {
    path: "logout",
    component: LogoutComponent,
  },
];
