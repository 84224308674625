import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TimeFormat } from "./filter.pipe";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
// Routing Module
import { AppRoutes } from "./app.routing";
// Directives
import { ApiService } from "./api.service";
import { JwtModule } from "@auth0/angular-jwt";
import { SCInterceptor } from "./_interceptor/sc.interceptor";
import { CookieService } from "ngx-cookie-service";
import { HeaderComponent } from "./layout/header/header.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { LogoutComponent } from "./logout/logout.component";
import { LoaderComponent } from "./../app/shared/loader/loader.component";
import { NgxLoadingModule } from "ngx-loading";

import { environment } from "src/environments/environment";
import { NgxAgoraModule } from "ngx-agora";
import { ToastrModule } from 'ngx-toastr';
import { PatientChatToast } from  "../app/components/patient-chat/patient-chat.toast";

export function jwtgetter() {
  return localStorage.getItem("access_token");
}

@NgModule({
  imports: [
    // NgbModule.forRoot(),
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
    }),
    NgxLoadingModule.forRoot({}),
    JwtModule.forRoot({
      config: {
        whitelistedDomains: [],
        blacklistedRoutes: [],
        tokenGetter: jwtgetter,
      },
    }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxAgoraModule.forRoot({ AppID: environment.agora.appId }),
    NgbModule,
    ToastrModule.forRoot({
      toastComponent: PatientChatToast,
      timeOut: 2000,
      progressBar: true,
      progressAnimation: "increasing",
      preventDuplicates: true,
    }),
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    TimeFormat,
    LoaderComponent,
    PatientChatToast,
  ],
  entryComponents: [PatientChatToast],
  providers: [
    ApiService,
    CookieService,
    TimeFormat,
    LogoutComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SCInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule, TimeFormat],
})
export class AppModule {}
