import { Component, AfterViewInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { LoginService } from "../../login/login.service";
import { Router } from "@angular/router";
import { ngxLoadingAnimationTypes } from "ngx-loading";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
})
export class LoaderComponent implements AfterViewInit {
  public loading = true;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  constructor(private router: Router, private loginService: LoginService) {}

  ngAfterViewInit() {
    if (!this.loginService.isLoggedIn()) {
      let url = environment.mainUrl + "/#/login";
      window.location.href = url;
    } else {
      this.loginService.navigatePatient();
    }
  }
}
