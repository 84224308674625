import { Router } from "@angular/router";
import { ApiService } from "../api.service";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from "ngx-cookie-service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

// user.ts
export interface LoginResponse {
  access_token: string;
  data: any;
  name: string;
  status: string;
  message: string;
}

@Injectable({
  providedIn: "root",
})
export class LoginService {
  locastrg: any;
  constructor(
    public jwtHelper: JwtHelperService,
    private cookieService: CookieService,
    private http: HttpClient,
    private api: ApiService,
    private route: Router
  ) {
    this.locastrg = localStorage;
  }

  getToken() {
    return this.cookieService.get("access_token");
  }
  getUserData() {
    const token = this.getToken();
    if (token) {
      return this.jwtHelper.decodeToken(token);
    }
    this.logout();
  }

  isExpired() {
    const token = this.getToken();
    return this.jwtHelper.isTokenExpired(token);
  }

  getUserRole() {
    const userData = this.getUserData();
    return userData.role;
  }

  // Checking if token is set
  isLoggedIn() {
    if (this.isExpired() || this.getUserRole() != "patient") {
      return false;
    } else {
      return true;
    }
  }

  // After clearing localStorage redirect to login screen
  logout() {
    const url = environment.baseUrl.replace(/(^\w+:|^)\/\//, "");
    this.cookieService.delete("access_token", "/", "." + url);
    // let loginurl = environment.mainUrl;
    // const url1 = environment.baseUrl.replace(/(^\w+:|^)\/\//, "");
    // this.cookieService.delete("access_token", "/", "." + url1);
    let mainUrl = environment.mainUrl + "/#/login";
    let result = this.http.get(mainUrl);
    result.subscribe((res) => {});
    setTimeout(() => {
      window.location.href = mainUrl;
    }, 100);
  }

  navigatePatient() {
    this.api.getPatientData().subscribe(
      (response: any) => {
        let patientInfo = response.data;
        if (
          patientInfo.otp_verified == 1 &&
          // patientInfo.is_verify == 1 &&
          patientInfo.is_profile_completed == 1
        ) {
          this.route.navigate(["appointments"]);
        } else {
          let otpUrl = environment.mainUrl + "/#/registration/otp-verification";
          // let idUrl = environment.mainUrl + "/#/registration/id-verification";
          window.location.href = otpUrl;
          // window.location.href = patientInfo.otp_verified == 0 ? otpUrl : idUrl;
        }
      },
      (err) => {}
    );
  }
}
