export const environment = {
  production: false,
  mainUrl: "https://app.secondconsult.com",
  apiUrl: "https://api-prod.secondconsult.com/api/",
  rladminUrl: "https://rladmin.secondconsult.com",
  adminUrl: "https://admin.secondconsult.com",
  rlUrl: "https://rl.secondconsult.com",
  baseUrl: "https://secondconsult.com",
  paymentUrl: "https://api-prod.secondconsult.com/api/",
  patientUrl: "https://patient.secondconsult.com/",
  assetUrl: "https://api-prod.secondconsult.com",
  agora: {
    appId: "76c42063accb47669d11b88f8dd4a4d8",
  },
};