import { Router } from '@angular/router';
import { HeaderService } from "./header.service";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { LoginService } from "../../login/login.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  profile_pic: any;
  patient_name: any;
  assetUrl = environment.assetUrl;
  uniqueId: any;
  show: boolean = false;
  is_next_apt: any;
  membership_details: any;

  constructor(
    private loginservice: LoginService,
    private headerService: HeaderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // this.profile_pic = "assets/images/default_profilepic.png";

    this.headerService.PatientDetails().subscribe(
      (jsonData) => {
        this.profile_pic = null;
        let data;
        data = jsonData.data;
        this.is_next_apt = data.next_appointment;
        this.patient_name = data.first_name + " " + data.last_name;
        this.profile_pic = data.profile_pic ? data.profile_pic : null;
        this.uniqueId = data.profile_uniqueid;
        this.membership_details = data.membership_details;
        if(data.timezone === "Asia/Kolkata") {
          this.is_next_apt.timezone = "IST";
        }
      },
      (err) => {}
    );
  }
  async reloadPage(){
    await this.router.navigate(['/appointment-details',{id : this.is_next_apt.bookingunique_id}]);
    window.location.reload()
  }
  toggleCollapse() {
    this.show = !this.show;
  }
  setProfile(profile_pic, name) {
    this.profile_pic = profile_pic;
    this.patient_name = name;
  }
  gotoHome() {
    window.location.href = environment.mainUrl;
  }
  logout() {
    this.loginservice.logout();
  }
}
