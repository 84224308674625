<!-- Navigation -->
<ul class="navbar-nav" (click)="headercomponent.toggleCollapse()">
  <!-- <li class="nav-item">
    <a
      class="nav-link"
      routerLink="/patient-dashboard"
      aria-controls="tab1"
      role="tab"
      data-toggle="tab"
      routerLinkActive="activelink"
      routerLinkActiveOptions="{exact:true}"
    >
      <i class="fa fa-tachometer"></i> Dashboard
    </a>
  </li> -->
  <li class="nav-item">
    <a
      class="nav-link"
      routerLink="/appointments"
      aria-controls="tab2"
      role="tab"
      data-toggle="tab"
      routerLinkActive="activelink"
      routerLinkActiveOptions="{exact:true}"
    >
      <i class="fe fe-calendar"></i> My Appointments
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      routerLink="/second-opinion"
      aria-controls="tab3"
      role="tab"
      data-toggle="tab"
      routerLinkActive="activelink"
      routerLinkActiveOptions="{exact:true}"
    >
      <i class="fa fa-handshake-o" style="padding-right: 11px"></i> Second
      Opinion
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      routerLink="/my-doctors"
      aria-controls="tab4"
      role="tab"
      data-toggle="tab"
      routerLinkActive="activelink"
      routerLinkActiveOptions="{exact:true}"
    >
      <i class="fa fa-user-md"></i> My Doctors
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      routerLink="/patient-reports"
      aria-controls="tab5"
      role="tab"
      data-toggle="tab"
      routerLinkActive="activelink"
      routerLinkActiveOptions="{exact:true}"
    >
      <i class="fa fa-heartbeat"></i> Prescriptions & Healthwallet
    </a>
  </li>
  
  <li class="nav-item">
    <a
      class="nav-link"
      routerLink="/transactions"
      aria-controls="tab6"
      role="tab"
      data-toggle="tab"
      routerLinkActive="activelink"
      routerLinkActiveOptions="{exact:true}"
    >
      <i class="fa fa-exchange"></i> Transactions
    </a>
  </li>
   <li class="nav-item">
    <a class="nav-link" routerLink="/invoice-product" aria-controls="tab6" role="tab" data-toggle="tab"
      routerLinkActive="activelink" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-exchange"></i> Smart Device Invoice
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/help-videos" routerLinkActive="active">
      <i class="fa fa-video-camera mr-4"></i>Help Videos
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link cursor-pointer" (click)="logout()">
      <i class="fa fa-sign-out"></i> Logout
    </a>
  </li>
</ul>
